<template>
  <tp-modal name="modal-product-option-quick-create" max-width="520px">
    <v-card slot-scope="props">
      <v-card-title class="font-weight-bold px-5">
        Thêm mới nhanh tùy chọn sản phẩm
      </v-card-title>

      <div
        v-if="props.payload.oldProductOption"
        class="amber lighten-5 rounded mt-1 mx-5 px-3 py-2"
      >
        <span>Cho phép tạo nhanh tùy chọn sản phẩm của </span>
        <span class="font-weight-bold">{{
          props.payload.oldProductOption.product_name
        }}</span
        >. Lưu ý rằng tùy chọn mới này được sử dụng
        <span class="font-weight-bold">một lần</span> và
        <span class="font-weight-bold">không</span> hoặc
        <span class="font-weight-bold">ít</span> được tái sử dụng sau này. Hệ
        thống sẽ mặc định ẩn tùy chọn này trên website.
      </div>

      <v-row class="mx-5 mt-5" no-gutters>
        <v-col cols="12">
          <div class="font-weight-bold mr-2 mb-1">Tên tùy chọn:</div>
          <v-textarea
            v-model="productOption.name"
            class="text-body-1"
            dense
            flat
            no-resize
            outlined
            placeholder="Intel Core i5, 4GB RAM..."
            rows="2"
            solo
            single-line
          ></v-textarea>
        </v-col>
        <v-col class="pr-2" cols="6">
          <div class="font-weight-bold mr-2 mb-1">Mã SKU:</div>
          <v-text-field
            v-model="productOption.SKU"
            class="text-body-1"
            dense
            flat
            placeholder="Nhập SKU"
            outlined
            solo
            single-line
          ></v-text-field>
        </v-col>
        <v-col class="pl-2" cols="6">
          <div class="font-weight-bold mr-2 mb-1">Giá bán:</div>
          <tp-input-price
            v-model="productOption.price"
            custom-class="text-body-1"
            dense
            placeholder="Nhập giá bán"
            outlined
            single-line
          ></tp-input-price>
        </v-col>
      </v-row>

      <v-card-actions class="px-5 pb-6 pt-0">
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="closeModal">
          Hủy
        </v-btn>
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          :loading="
            productOptionStatusRequest.value === 'loading-createProductOption'
          "
          @click="
            productOptionStatusRequest.value === 'loading-createProductOption'
              ? null
              : createProductOption(
                  props.payload.oldProductOption,
                  props.payload.onCompleteCreating
                    ? props.payload.onCompleteCreating
                    : undefined
                )
          "
        >
          Thêm mới
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },
    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-product-option-quick-create" });
      this.$store.dispatch("PRODUCT_OPTION/resetProductOption");
    },

    async createProductOption(oldProductOption, onCompleteCreating) {
      this.productOption.front_display = 0;
      // Request create
      await this.$store.dispatch("PRODUCT_OPTION/createProductOption", {
        productId: oldProductOption.id,
        productOption: this.productOption
      });

      if (
        this.productOptionStatusRequest.value === "success-createProductOption"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm mới thành công"
          }
        });

        // Handle after complete creating
        if (typeof onCompleteCreating === "function") {
          onCompleteCreating({ ...this.productOption });
        }

        this.$modal.hide({ name: "modal-product-option-quick-create" });
      } else if (
        this.productOptionStatusRequest.value === "error-createProductOption"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.productOptionStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
